import React, { Fragment } from "react"
import { Helmet } from "react-helmet"

export default function AboutUsPage() {
  return (
    <Fragment>
      <Helmet>
        <script>
          {`
          (function (w, d, s, o, f, js, fjs) {
            w[o] =
                w[o] ||
                function () {
                    (w[o].q = w[o].q || []).push(arguments);
                };
            (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
            js.id = o;
            js.src = f;
            js.async = 1;
            fjs.parentNode.insertBefore(js, fjs);
        })(window, document, "script", "_hw", ' https://files.withcherry.com/widgets/widget.js');
        _hw(
            "init",
            {
        debug: false,
        variables: {
          slug: "chamberlain-general-and-sedation-dentistry",
          name: "Chamberlain General and Sedation Dentistry",
        },
        styles: {
          primaryColor: "#00adee",
          secondaryColor: "#00adee10",
          fontFamily: "Montserrat",
        },
      },
      ["all", "hero", "howitworks", "testimony", "faq", "calculator"]
        );
        
        setTimeout(() => {
            
            document.getElementsByTagName('body')[0].style.overflowY = "unset !important"
            }, 500)
        
        `}
        </script>
      </Helmet>
      <link
        href="https://fonts.googleapis.com/css2?family=Playfair+Display&family=Slabo+27px&family=Lato&family=Raleway&family=Montserrat&family=Oswald&family=Poppins&family=Source+Sans+Pro&family=PT+Sans&family=Open+Sans&display=swap"
        rel="stylesheet"
      />
      <div id="all"></div>
    </Fragment>
  )
}
